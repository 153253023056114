import { TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";

const UsersLocationsForms = () => {

  const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()


  const endpoint = "administration/users/locations";
  const [t, i18n] = useTranslation("global");

  const api = useFetch();
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');
  const [company, setCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const profile = localStorage.getItem("profile");
  const [description, setDescription] = useState('');
  const [disabledCL, setDisabledCL] = useState(false);

  const nav = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    location_id: "",
    user_id: "",
    company_id: "",
    userlocation_position: "",
  });

  const [locations, setLocations] = useState([]);
  const [users, setUsers] = useState([]);

  // Permisos
  const handlerApp = HandlerApp();
  const [permissions, setPermissions] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const { app } = useContext(AppContext);
  useEffect(() => {
    setPermissions(app.permissions[endpoint]);
  }, [app]);

  useEffect(() => {
    setTitle(t("usersLocationsComponent.createUserLocation"));
    setDescription(t('description.userLocation_create'));
    if (id !== undefined) {
      setTitle(t('usersLocationsComponent.updateUserLocation'));
      setDescription(t('description.userLocation_update'));
    }
  }, [t])

  const handleDependencies = (data) => {
    let d = filterData(data, formData);
    setFormData(d);
  };

  const filterData = (data, formData) => {
    let finalData = {};
    for (let key in formData) {
      finalData[key] = data[key];

      setValue(key, data[key]);


    }
    return finalData;
  };

  const sendRequest = () => {
    api
      .get(endpoint + "/" + id)
      .then((response) => {
        if (response.success) {
          let data = response.data[0];
          handleDependencies(data);
          setCompany(data.company_id);
          handleSelectLocation(data.company_id);
          handlerApp.setLoader(false);
        } else {
          handlerApp.showError(response.message);
          setDisableButton(true);
          handlerApp.setLoader(false);
        }
      })
      .catch((error) => {
        handlerApp.setLoader(false);
        handlerApp.showError();
      });
  };

  useEffect(() => {
    handlerApp.setLoader(true);
    api
      .get(endpoint, {
        action: "getParamsUpdate",
      })
      .then((response) => {
        if (response.success) {
          setData(response.data);
          setUsers(handlerApp.getResultFromObject(response.data, "users"));
          if (profile == 1 || profile == 2) {
            setCompanies(handlerApp.getResultFromObject(response.data, "companies"));
          }
          setLocations(handlerApp.getResultFromObject(response.data, "locations"));

          if (id !== undefined) {
            sendRequest();
          } else {
            handlerApp.setLoader(false);
          }
        } else {
          handlerApp.setLoader(false);
        }
      })
      .catch((error) => {
        handlerApp.setLoader(false);
        handlerApp.showError();
      });
  }, []);

  const handleForm = (e, name = null, dependency = null) => {
    let key = name !== null ? name : e.target.name;
    setFormData({
      ...formData,
      [key]: e.target.value,
    });

    if (dependency !== null) {
      dependency(e.target.value);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    nav(-1);
  };

  // save data
  const handleSubmitLocal = (data) => {

    handlerApp.setLoader(true);
    api
      .post(endpoint, formData)
      .then((response) => {
        if (response.success) {
          handlerApp.showOk(response);
          handlerApp.setLoader(false);
          nav("/" + endpoint);
        } else {
          handlerApp.handlerResponse(response);
          handlerApp.setLoader(false);
        }
      })
      .catch((error) => {
        handlerApp.showError();
        handlerApp.setLoader(false);
      });
  };

  const handleUpdate = (data) => {
    handlerApp.setLoader(true);
    api
      .put(endpoint + "/" + id, formData)
      .then((response) => {
        if (response.success) {
          handlerApp.showOk(response);
          handlerApp.setLoader(false);
          nav("/" + endpoint);
        } else {
          handlerApp.handlerResponse(response);
          handlerApp.setLoader(false);
        }
      })
      .catch((error) => {
        handlerApp.showError();
        handlerApp.setLoader(false);
      });
  };

  const handleAutocomplete = (event, newValue) => {
    setFormData({
      ...formData,
      user_id: newValue ? newValue.code : null,
    });
  };

  const handleKeyDow = (event) => {
    // Verifica si la tecla presionada es Enter
    if (event.key === 'Enter') {
      // Evita el comportamiento predeterminado del formulario
      event.preventDefault();
    }
  };

  const handleSelectCompany = (val) => {
    setCompany(val);
    setLocations([]);
    handlerApp.setLoader(true);
    handleSelectLocation(val, true)
  };

  const handleSelectLocation = (val, fromSelect = false) => {
    api.get(endpoint, {
      action: "getLocation",
      company: val,
    })
      .then(response => {
        if (response.success) {
          const parsedLocations = JSON.parse(response.data[0].coalesce);
          setLocations(parsedLocations);
          if (fromSelect) {
            handlerApp.setLoader(false);
          }
        } else {
          handlerApp.setLoader(false);
          handlerApp.handlerResponse(response);
        }
      })
      .catch(error => {
        handlerApp.showError(error);
      });
  };

  return (
    <div className="app container">
      <div className="title-section-forms">
        {title}
        <p>{description}</p>
      </div>
      <div className="section-forms">
        <form onSubmit={handleSubmit((id === undefined) ? handleSubmitLocal : handleUpdate)} noValidate onKeyDown={handleKeyDow}>
          <div className="row">
            {(profile == 1 || profile == 2) && (
              <div className="col-md-6 mb-2">
                <BoldSelectValidated
                  title={t('companyComponent.company') + " *"}
                  value={formData.company_id}
                  name="company_id"
                  options={companies}
                  onChange={handleForm}
                  register={register}
                  errors={errors}
                  required={true}
                  dependency={handleSelectCompany}
                  disable={disabledCL}
                />
              </div>
            )}

            <div className="col-md-6 mb-2">
              <BoldSelectValidated
                title={t("locationComponent.location") + " *"}
                value={formData.location_id}
                name="location_id"
                onChange={handleForm}
                options={locations}
                register={register}
                errors={errors}
                required={true}
                disable={profile == 3 ? false : !company}
              />
            </div>

            <div className="col-md-6 mb-2">
              <Autocomplete
                value={users.find((user) => user.code === formData.user_id) || null}
                onChange={handleAutocomplete}
                id="user_id"
                name="user_id"
                options={users}
                getOptionLabel={(option) => option.name}
                renderInput={(params) =>
                  <TextField {...params}
                    label={t("user")}
                    {...register("user_id", { required: true })}
                    error={errors.user_id != null}
                    helperText={errors.user_id?.type === 'required' ? t('validationErrorMessages.required') : ""}
                    variant="standard"
                    size="small"
                    required />}
              />
            </div>

            <div className="col-md-6 mb-2">
              <TextField label={t("usersLocationsComponent.userlocation_position")} variant="standard" size="small"
                {...register("userlocation_position", { maxLength: 80 })}
                value={formData.userlocation_position}
                onChange={handleForm}
                error={errors.userlocation_position != null}
                helperText={
                  // errors.userlocation_position?.type === 'required' ? t('validationErrorMessages.required') :
                  errors.userlocation_position?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 80  ${t('validationErrorMessages.longSuffix')}` : ""
                }
              />
            </div>

            <div className="row d-flex justify-content-center">
              <div className="col-md-3 d-flex justify-content-around">
                <button
                  className="bold-btn bold-btn-light"
                  onClick={handleCancel}
                >
                  {t("cancel")}
                </button>
                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                {id && !disableButton && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UsersLocationsForms;
