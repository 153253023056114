import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import BoldSelect from "../../../utils/BoldSelect";
import HandlerApp from "../../../utils/handlerApp";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LocationContext } from "../../../context/LocationContext";
import { useForm, Controller } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";


const LocationsForms = () => {
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();
  const [storedMode, setStoredMode] = useState(false);
  const endpoint = "administration/locations";
  const [t, i18n] = useTranslation("global");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const api = useFetch();
  const [data, setData] = useState([]);
  const nav = useNavigate();
  const { id } = useParams();

  const { checkLocations } = useContext(LocationContext);

  const [formData, setFormData] = useState({
    location_name: "",
    company_id: "",
    location_manager: "",
    country_code: "",
    state_code: "",
    city_code: "",
    location_time_zone: "",
    location_address: "",
    location_zipcode: "",
    language_id: "",
    currency_id: "",
    password_default: "",
  });

  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState("");
  const [managers, setManagers] = useState([]);
  const [manager, setManager] = useState("");
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [states, setStates] = useState([]);
  const [state, setState] = useState("");
  const [cities, setCities] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const [city, setCity] = useState("");
  const [currencies, setCurrencies] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState("");

  // Permisos
  const handlerApp = HandlerApp();
  const [permissions, setPermissions] = useState([]);
  const { app, modeTheme } = useContext(AppContext);

  useEffect(() => {
    setPermissions(app.permissions[endpoint]);
  }, [app]);

  useEffect(() => {
    setTitle(t("locationComponent.createLocation"));
    setDescription(t("description.create_location"));
    if (id !== undefined) {
      setTitle(t("locationComponent.updateLocation"));
      setDescription(t("description.edit_location"));
    }
  }, [t]);

  const handleDependencies = (data) => {
    setCountry(data.country_code);
    setState(data.state_code);
    let d = filterData(data, formData);
    // setFormData(filterData(data));
    setFormData(d);
  };

  const filterData = (data, formData) => {
    let finalData = {};
    for (let key in formData) {
      finalData[key] = data[key];
      setValue(key, data[key]);
    }
    return finalData;
  };

  const sendRequest = () => {
    api
      .get(endpoint + "/" + id)
      .then((response) => {
        if (response.success) {
          let data = response.data[0];
          data.password_default = handlerApp.decode(data.password_default);
          handleDependencies(data);
          setDisableButton(false);

          //setFormData(data);

          // setData(response.data)
          handlerApp.setLoader(false);
        } else {
          setDisableButton(true);
          handlerApp.setLoader(false);
          handlerApp.showError(response.message);
        }
      })
      .catch((error) => {
        handlerApp.setLoader(false);
        handlerApp.showError();
      });
  };

  useEffect(() => {
    handlerApp.setLoader(true);
    api
      .get(endpoint, {
        action: "getParamsUpdate",
      })
      .then((response) => {
        if (response.success) {
          setData(response.data);
          setCompanies(
            handlerApp.getResultFromObject(response.data, "companies")
          );
          setManagers(
            handlerApp.getResultFromObject(response.data, "managers")
          );
          setCountries(
            handlerApp.getResultFromObject(response.data, "countries")
          );
          setCurrencies(
            handlerApp.getResultFromObject(response.data, "currencies")
          );
          setLanguages(
            handlerApp.getResultFromObject(response.data, "languages")
          );
          setTimeZones(
            handlerApp.getResultFromObject(response.data, "timezones"));

          if (id !== undefined) {
            sendRequest();
          } else {
            handlerApp.setLoader(false);
          }
        } else {
          handlerApp.setLoader(false);
        }
      })
      .catch((error) => {
        handlerApp.setLoader(false);
        handlerApp.showError();
      });
  }, []);

  const handleForm = (e, name = null, dependency = null) => {
    let key = name !== null ? name : e.target.name;
    let value = e.target.value;
    if (key === "password_default") {
      setVisiblePassword(value); // Guarda la contraseña sin codificar
      // value = handlerApp.encode(value);
    }
    setFormData({
      ...formData,
      [key]: value,
    });

    setValue(key, e.target.value);
    if (dependency !== null) {
      dependency(value);
    }
  };

  // countries
  const handleSelectCountry = (val) => {
    setCountry(val);
    setStates([]);
    setCities([]);
  };

  //companies
  const handleSelectCompanies = (val) => {
    setCompany(val);
    const selectedCompany = companies.find(company => company.code === val);
    if (selectedCompany && selectedCompany.time_zone) {
      setFormData({
        ...formData,
        company_id: val,
        location_time_zone: selectedCompany.time_zone,
      });
      setValue("location_time_zone", selectedCompany.time_zone);
    } else {
      setFormData({
        ...formData,
        company_id: val,
        location_time_zone: "",
      });
      setValue("location_time_zone", "");
    }
  };


  const getState = () => {
    if (country !== undefined && country !== "") {
      handlerApp.setLoader(true);
      api
        .get(endpoint, {
          action: "getStates",
          country: country,
        })
        .then((response) => {
          if (response.success) {
            setStates(response.data);
            handlerApp.setLoader(false);
          } else {
            handlerApp.setLoader(false);
            handlerApp.handlerResponse(response);
          }
        })
        .catch((error) => {
          handlerApp.showError();
        });
    }
  };
  useEffect(() => {
    getState();
  }, [country]);

  // states
  const handleSelectState = (val) => {
    setState(val);
    setCities([]);
  };

  const getCities = () => {
    if (state !== undefined && state !== "") {
      handlerApp.setLoader(true);
      api
        .get(endpoint, {
          action: "getCities",
          country: country,
          state: state,
        })
        .then((response) => {
          if (response.success) {
            setCities(response.data);
            handlerApp.setLoader(false);
          } else {
            handlerApp.handlerResponse(response);
            handlerApp.setLoader(false);
          }
        })
        .catch((error) => {
          handlerApp.showError();
        });
    }
  };
  useEffect(() => {
    getCities();
  }, [state]);

  // cities
  const handleSelectCity = (val) => {
    setCity(val);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    nav(-1);
  };

  // save data
  const handleSubmitLocal = (data) => {

    handlerApp.setLoader(true);
    formData.password_default = handlerApp.encode(data.password_default);
    api
      .post(endpoint, formData)
      .then((response) => {
        if (response.success) {
          checkLocations();
          handlerApp.showOk(response);
          handlerApp.setLoader(false);
          nav("/" + endpoint);
        } else {
          handlerApp.handlerResponse(response);
          handlerApp.setLoader(false);
        }
      })
      .catch((error) => {
        handlerApp.showError();
        handlerApp.setLoader(false);
      });
  };

  const handleUpdate = (data) => {
    handlerApp.setLoader(true);
    formData.password_default = handlerApp.encode(data.password_default);
    api
      .put(endpoint + "/" + id, formData)
      .then((response) => {
        if (response.success) {
          checkLocations();
          handlerApp.showOk(response);
          handlerApp.setLoader(false);
          nav("/" + endpoint);
        } else {
          handlerApp.handlerResponse(response);
          handlerApp.setLoader(false);
        }
      })
      .catch((error) => {
        handlerApp.showError(error);
        handlerApp.setLoader(false);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };


  const handleKeyDow = (event) => {
    // Verifica si la tecla presionada es Enter
    if (event.key === 'Enter') {
      // Evita el comportamiento predeterminado del formulario
      event.preventDefault();
    }
  };

  return (
    <div className="app container">
      <div className="title-section-forms">
        {title}
        <p>{description}</p>
      </div>
      <div className="section-forms">
        <form
          onSubmit={handleSubmit(
            id === undefined ? handleSubmitLocal : handleUpdate
          )}
          onKeyDown={handleKeyDow}
        >
          <div className="row">
            <div className="col-md-6 mb-2" style={{color:'white'}}>
              <TextField
                label={t("name")+" *"}
                variant="standard"
                size="small"
                {...register("location_name", {
                  required: true,
                  maxLength: 80,
                })}
                value={formData.location_name}
                onChange={handleForm}
                error={errors.location_name != null}
                helperText={
                  errors.location_name?.type === "required"
                    ? t("validationErrorMessages.required")
                    : errors.location_name?.type === "maxLength"
                      ? `${t("validationErrorMessages.long")} 80  ${t(
                        "validationErrorMessages.longSuffix"
                      )}`
                      : ""
                }

              />
            </div>
            <div className="col-md-6 mb-2">
              <BoldSelectValidated
                title={t("companyComponent.company")+" *"}
                name="company_id"
                options={companies}
                value={formData.company_id}
                onChange={handleForm}
                dependency={handleSelectCompanies}
                register={register}
                errors={errors}
                required={true}
              />
            </div>
            <div className="col-md-6 mb-2">
              <BoldSelectValidated
                title={t("locationComponent.manager")}
                name="location_manager"
                options={managers}
                value={formData.location_manager}
                onChange={handleForm}
                register={register}
                errors={errors}
              />
            </div>
            <div className="col-md-6 mb-2">
              <BoldSelectValidated
                title={t("locationComponent.currency")+" *"}
                name="currency_id"
                options={currencies}
                value={formData.currency_id}
                onChange={handleForm}
                register={register}
                errors={errors}
                required={true}
              />
            </div>
            <div className="col-md-6 mb-2">
              <BoldSelectValidated
                title={t("locationComponent.language")+" *"}
                name="language_id"
                options={languages}
                value={formData.language_id}
                onChange={handleForm}
                register={register}
                errors={errors}
                required={true}
              />
            </div>
            <div className="col-md-6 mb-2">
              <BoldSelectValidated
                title={t("country")+" *"}
                name="country_code"
                options={countries}
                value={formData.country_code}
                onChange={handleForm}
                dependency={handleSelectCountry}
                register={register}
                errors={errors}
                required={true}
              />
            </div>
            <div className="col-md-6 mb-2">
              <BoldSelectValidated
                title={t("state")+" *"}
                name="state_code"
                options={states}
                value={formData.state_code}
                onChange={handleForm}
                dependency={handleSelectState}
                register={register}
                errors={errors}
                required={true}
              />
            </div>
            <div className="col-md-6 mb-2">
              <BoldSelectValidated
                title={t("city")+" *"}
                name="city_code"
                options={cities}
                value={formData.city_code}
                onChange={handleForm}
                dependency={handleSelectCity}
                register={register}
                errors={errors}
                required={true}
              />
            </div>
            <div className="col-md-6 mb-2">
              <BoldSelectValidated
                title={t("locationComponent.timeZone")+" *"}
                name="location_time_zone"
                options={timeZones}
                value={formData.location_time_zone}
                onChange={handleForm}
                register={register}
                errors={errors}
                required={true}
              />
            </div>
            <div className="col-md-6 mb-2">
              <TextField
                label={t("address")+" *"}
                variant="standard"
                size="small"
                {...register("location_address", {
                  required: true,
                  maxLength: 120,
                })}
                value={formData.location_address}
                onChange={handleForm}
                error={errors.location_address != null}
                helperText={
                  errors.location_address?.type === "required"
                    ? t("validationErrorMessages.required")
                    : errors.location_address?.type === "maxLength"
                      ? `${t("validationErrorMessages.long")} 120  ${t(
                        "validationErrorMessages.longSuffix"
                      )}`
                      : ""
                }
              />
            </div>
            <div className="col-md-6 mb-2">
              <TextField
                label={t("zipCode")}
                variant="standard"
                size="small"
                {...register("location_zipcode", {
                  maxLength: 8,
                })}
                value={formData.location_zipcode}
                onChange={handleForm}
                error={errors.location_zipcode != null}
                helperText={
                  errors.location_zipcode?.type === "maxLength"
                      ? `${t("validationErrorMessages.long")} 8  ${t(
                        "validationErrorMessages.longSuffix"
                      )}`
                      : ""
                }
              />
            </div>
            
            <div className="row d-flex justify-content-center">
              <div className="col-md-3 d-flex justify-content-around">
                <button
                  className="bold-btn bold-btn-light"
                  onClick={handleCancel}
                >
                  {t("cancel")}
                </button>
                {id === undefined && (
                  <button className="bold-btn bold-btn-dark" type="submit">
                    {t("submit")}
                  </button>
                )}
                {id && !disableButton && (
                  <button className="bold-btn bold-btn-dark" type="submit">
                    {t("update")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LocationsForms;
