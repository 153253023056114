
import { useEffect, useState, useMemo, useContext } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import imageNotFound from "../../../assets/img/image-not-found.webp";
import Swal from "sweetalert2";
import NoDataTables from "../../noDataTable/NoDataTables";
import MassActionButtons from "../../molecules/MassActionButtons";
import { Checkbox } from "@mui/material";
const Coupons = () => {
    const endpoint = "configuration/coupons";
    const [t,i18n] = useTranslation("global");
    const title = t('couponComponent.coupons');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const [company, setCompany] = useState("");
    const handlerApp = HandlerApp();
    
    const [permissions,setPermissions] = useState([]);
    
    const {app, modeTheme} = useContext(AppContext);

    const [buttonSelected, setButtonSelected] = useState(false);
    const [rowsSelected, setRowsSelected] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 912);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 912);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
                deleteRecords(id)
            break;
            case 'deleteSelected':
                const selectedIds = rowsSelected.map(row => row.coupon_id);
                deleteRecords(selectedIds);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
            case 'activateSelected':
                updateSelectedRowsStatus(1);
                break;
            case 'deactivateSelected':
                updateSelectedRowsStatus(0);
                break;

        }
    }

    const deleteRecords = (ids) => {
        const isMultiple = Array.isArray(ids);
        const alertTitle = isMultiple ? t('DeleteAlert.DeleteSelectedRecords') : t('DeleteAlert.DeleteRecord');
        const endpointUrl = isMultiple ? `${endpoint}/all` : `${endpoint}/${ids}`;
        let body = {
            codes: ids
        }
        Swal.fire({
            icon: 'info',
            title: alertTitle,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: t('DeleteAlert.delete'),
            confirmButtonColor: "red",
            cancelButtonText: t('cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
    
                const request = isMultiple 
                    ? api.deleteMethod(endpointUrl, body) 
                    : api.deleteMethod(endpointUrl);
    
                request
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            if (isMultiple) {
                                setButtonSelected(false);
                                setRowsSelected([]);
                                handleClearRows();
                            }
                            sendRequest();
                        } else {
                            handlerApp.handlerResponse(response);
                            handlerApp.setLoader(false);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                        handlerApp.setLoader(false);
                    });
            }
        });
    };

    const updateSelectedRowsStatus = (status) => {
        Swal.fire({
            icon: 'info',
            title: status === 1 ? `${t('activeAlert.activateSelectedRecords')}` : `${t('inactiveAlert.inactiveSelectedRecords')}`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: status === 1 ? `${t('activeAlert.active')}` : `${t('inactiveAlert.inactive')}`,
            confirmButtonColor: status === 1 ? "#08a215" : "#d32f2f",
            cancelButtonText: `${t('cancel')}`
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                const ids = rowsSelected.map(row => row.coupon_id);
                let body = {
                    action: 'updateStatus',
                    status: status,
                    codigos: ids
                }
                api.put(endpoint+'/all', body)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            setButtonSelected(false);
                            setRowsSelected([]);
                            sendRequest();
                            handleClearRows();
                        } else {
                            handlerApp.handlerResponse(response);
                            handlerApp.setLoader(false);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                        handlerApp.setLoader(false);
                    });
            }
        });
    }


    const optionRequest = (id, action) => {
        Swal.fire({
            title: action === 'active' ? `${t('activeAlert.activeRecord')}` : `${t('inactiveAlert.inactiveAlert')}`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: `${t('cancel')}`,
            confirmButtonText: action === 'active' ? `${t('activeAlert.active')}` : `${t('inactiveAlert.inactive')}`
        }).then((result) => {
            if (result.isConfirmed) {
                executeAction(id, action);
            }
        });
    }
    
    const executeAction = (id, action) => {
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        handlerApp.setLoader(true);
        api.put(endpoint+"/"+id,body)
        
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const rowSelected = (state) => {
        setButtonSelected(state.selectedRows.length > 0);
        setRowsSelected(state.selectedRows);
    }

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    }
    const columns = [
        {
            id: 'coupon_id',
            name: 'id',
            selector: row => row.coupon_id,
            sortable: true,
            width: "70px"
        },
        {
            id: 'coupon_name',
            name: t('couponComponent.name'),
            selector: row => row.coupon_name,
            sortable: true
        },
        {
            id: 'coupon_code',
            name: t('couponComponent.code'),
            selector: row => row.coupon_code,
            sortable: true
        },
        {
            id: 'coupon_discount',
            name: t('couponComponent.descount'),
            selector: row => handlerApp.formatPercent(row.coupon_discount),
            sortable: true
        },
        {
            id: 'coupon_start_date',
            name: t('couponComponent.starDate'),
            selector: row => row.coupon_start_date,
            sortable: true
        },
        {
            id: 'coupon_end_date',
            name: t('couponComponent.endDate'),
            selector: row => row.coupon_end_date,
            sortable: true
        },
        {
            name: t("action"),
            cell:(row) => {  return handlerApp.actions(row, permissions,'coupon',handlerAction)},
            // cell:(row) => { return actions(row)},
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    const sendRequest = ()=>{
        setData([])
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                if (response.message !== "M001") {
                    handlerApp.handlerResponse(response);
                }
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }

    
    useEffect(() => {
        sendRequest();
    }, [])

    useEffect(()=>{
        setPermissions(app.permissions[endpoint])
    },[app])

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.coupon_name && item.coupon_name?.toLowerCase().includes(filterText.toLowerCase())
            || item.coupon_id?.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.coupon_code?.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.coupon_discount?.toString().toLowerCase().includes(filterText.toLowerCase())
    );

    // const subHeaderComponentMemo = useMemo(() => {
    //     return (
    //         <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText}></input>
    //     );
    // }, [filterText, resetPaginationToggle]);

    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

    return (
        <div className="app container">
            <div className="title-section-forms">
                <h3>{title}</h3>
                <p>{t("description.cupon")} </p>
            </div>
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            {!isSmallScreen && buttonSelected && (
                                <MassActionButtons
                                    permissions={permissions}
                                    handlerAction={handlerAction}
                                    t={t}
                                />
                            )}
                            <div className="action-buttons">
                                { permissions?.indexOf("create") !== -1 && (
                                    <button className="btn-table-actions" onClick={()=>{handlerAction(null,'create')}}>
                                        <span className="material-symbols-outlined">add</span>
                                    </button>
                                ) }
                                <button className="btn-table-actions" onClick={()=>{handlerAction(null,'refresh')}}>
                                    <span className="material-symbols-outlined">autorenew</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    {isSmallScreen && buttonSelected && (
                        <MassActionButtons
                            permissions={permissions}
                            handlerAction={handlerAction}
                            t={t}
                        />
                    )}
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        noDataComponent={<NoDataTables/>}
                        // subHeaderComponent={subHeaderComponentMemo}
                        selectableRows={(permissions?.indexOf("inactive") !== -1 || permissions?.indexOf("delete") !== -1)}
                        onSelectedRowsChange={rowSelected}
                        clearSelectedRows={toggledClearRows}
                        
                        selectableRowsComponent={Checkbox}
                        selectableRowsComponentProps={selectProps}
                        theme={modeTheme ? "solarized" : ""}
                    />
                </div>
            </div>
        </div>
    )
}

export default Coupons;