import { InputAdornment, TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useForm } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const CouponsForm = () => {

    const endpoint = "configuration/coupons";
    const [t] = useTranslation("global");
    const [title, setTitle] = useState('couponComponent.createCoupon');
    const [description, setDescription] = useState('');
    const api = useFetch();
    const nav = useNavigate();
    const handlerApp = HandlerApp();
    const { id } = useParams();
    const [identificatioType, setIdentificationType] = useState([]);
    const [formData, setFormData] = useState({
        coupon_name: "",
        coupon_code: "",
        coupon_discount: "",
        coupon_discount_type: "",
        coupon_start_date: "",
        coupon_end_date: "",
    });

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const { register, handleSubmit, formState: { errors }, setValue } = useForm()

    // permisos
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        setTitle(t("couponComponent.createCoupon"));
        setDescription(t("description.cupon_create"));
        if (id !== undefined) {
            setTitle(t('couponComponent.editCoupon'));
            setDescription(t('description.cupon_update'));
        }
    }, [t])

    useEffect(() => {
        if (startDate?.hasOwnProperty('_d')) {
            setFormData({ ...formData, coupon_start_date: startDate?.format('YYYY-MM-DD') });
        }
    }, [startDate]);

    useEffect(() => {
        if (endDate?.hasOwnProperty('_d')) {
            setFormData({ ...formData, coupon_end_date: endDate?.format('YYYY-MM-DD') });
        }
    }, [endDate]);

    useEffect(() => {
        handlerApp.setLoader(true);
        const type = {
            coupon_discount_type: {
                "FREE": "FREE",
                "LOCATION": "LOCATION",
                "SELF": "SELF",
                "POS": "POS",
            }
        };
        setIdentificationType(type.coupon_discount_type)
        if (id !== undefined) {
            setTitle('couponComponent.editCoupon')
            sendRequest();
        } else {
            handlerApp.setLoader(false);
        }
    }, [])

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        if (d.coupon_end_date != null&&d.coupon_start_date != null) {
            setEndDate(moment(d.coupon_end_date));
            setStartDate(moment(d.coupon_start_date));
        }
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }
    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        let value = e.target.value;

        if (key === 'coupon_discount') {
            if (/^\d*$/.test(value)) {
                setFormData({
                    ...formData,
                    [key]: e.target.value
                });
            }
        } else {
            setFormData({
                ...formData,
                [key]: e.target.value
            });
        }
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }
    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    setDisableButton(false)
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.showError(response.message);
                    setDisableButton(true)
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...formData
        }

        api.post(endpoint, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);

        let body = {
            ...formData
        }

        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    useEffect(() => {
    }, [identificatioType]);

    useEffect(() => {
    if (startDate && endDate && endDate < startDate) {
        setEndDate(null);
        setFormData({ ...formData, coupon_end_date: null });
    }
}, [startDate, endDate]);

    const handleKeyDow = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const optionsArray = Object.entries(identificatioType).map(([code, name]) => ({ code, name }));

    return (
        <div className="app container">
            <div className="title-section-forms">
                {t(title)}
                <p>{description} </p>
            </div>
            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)} noValidate onKeyDown={handleKeyDow}>
                    <div className="row">

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t("couponComponent.name")+" *"} variant="standard" size="small"
                                {...register("coupon_name", { required: true, maxLength: 120 })}
                                value={formData.coupon_name}
                                onChange={handleForm}
                                error={errors.coupon_name != null}
                                helperText={
                                    errors.coupon_name?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.coupon_name?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 120  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('couponComponent.code')+" *"}
                                variant="standard"
                                size="small"
                                {...register("coupon_code", { required: true, maxLength: 60 })}
                                value={formData.coupon_code}
                                onChange={handleForm}
                                error={errors.coupon_code != null}
                                helperText={
                                    errors.coupon_code?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.coupon_code?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 60  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('couponComponent.descount') + " *"}
                                variant="standard"
                                size="small"
                                name="coupon_discount"
                                {...register("coupon_discount", {
                                    required: true,
                                    maxLength: 2,
                                })}
                                value={formData.coupon_discount}
                                onChange={handleForm}
                                error={errors.coupon_discount != null}
                                helperText={
                                    errors.coupon_discount?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.coupon_discount?.type === 'pattern' ? t('validationErrorMessages.invalidPriceFormat') :
                                            errors.coupon_discount?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 2  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                inputProps={{
                                    maxLength: 2,
                                }}
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('couponComponent.descountType')+" *"}
                                value={formData.coupon_discount_type}
                                name="coupon_discount_type"
                                onChange={handleForm}
                                options={optionsArray}
                                register={register}
                                errors={errors}
                                required={true}
                            />

                        </div>

                        <div className="col-md-6 mb-2">
                            <DatePicker
                                label={t('couponComponent.starDate')+" *"}
                                format="YYYY/MM/DD"
                                name="coupon_start_date"
                                variant="standard"
                                onChange={(val) => { setStartDate(val) }}
                                value={startDate}
                                slotProps={{ textField: { size: 'small', variant: "standard" } }}
                                helperText={
                                    errors.coupon_start_date?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.coupon_start_date?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 30  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <DatePicker
                                label={t('couponComponent.endDate')+" *"}
                                format="YYYY/MM/DD"
                                name="coupon_end_date"
                                variant="standard"
                                onChange={(val) => { setEndDate(val) }}
                                value={endDate}
                                minDate={startDate}
                                slotProps={{ textField: { size: 'small', variant: "standard" } }}
                                helperText={
                                    errors.coupon_end_date?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.coupon_end_date?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 30  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && !disableButton && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CouponsForm;