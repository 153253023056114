import { createContext, useState } from "react";
import { useFetch } from "../hooks/useFecth";
import HandlerApp from "../utils/handlerApp";

const LocationContext = createContext();
const LocationContextProvider = ({children}) =>{

    const [location, setLocation] = useState("");
    const [company, setCompany] = useState("");
    const [companies, setcompanies] = useState([])
    const [locations, setLocations] = useState([])
    const api = useFetch();
    const handlerApp = HandlerApp();

    const checkLocations =()=>{
        setLocations([])
        localStorage.setItem("locations", JSON.stringify([]));
        let profile= localStorage.getItem("profile");
        api.get("administration/locations",{
            action: 'checkLocations'
        })
        .then(response =>{
            if(response.success){
                if(profile>2){
                    setLocations(response.data);
                    localStorage.setItem("locations", JSON.stringify(response.data));
                }else{
                    if(company !== ""){
                        setLocations(response.data);
                        localStorage.setItem("locations", JSON.stringify(response.data));
                    }
                }
            }else{
            }
        })
        .catch(error=>{
            handlerApp.showError("Error #E001");
        })
    }

    const getCompanies = () => {
        setcompanies([]);
        api.get("/administration/companies",{
            action: 'getAllCompanies'
        })
        .then(response =>{
            if(response.success){
                setcompanies(response.data);
            }else{
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error=>{
            handlerApp.showError("E001");
        })
    }

    return (
        <LocationContext.Provider value={{location, setLocation, locations, setLocations, checkLocations, company, setCompany, companies, getCompanies}}>
            {children}
        </LocationContext.Provider>
    )
}

export { LocationContext, LocationContextProvider };
