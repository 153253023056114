import HandlerApp from "../../../utils/handlerApp";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LocationContext } from "../../../context/LocationContext";

const PaymentProcessorsForm = () => {

    const { register, handleSubmit, formState: { errors }, setValue, control} = useForm()

    const endpoint      = "configuration/payment-processor";
    const handlerApp    = HandlerApp();
    const [t]           = useTranslation('global');
    const api           = useFetch();
    const nav           = useNavigate();
    const { id }        = useParams();
    const profile       = handlerApp.getProfile();
    
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [disableButton, setDisableButton] = useState(false);
    const [formData, setFormData] = useState({
        company_id: "",
        location_id: "",
        paymentprocessor_processor: "",
        paymentprocessor_merchant_id: "",
        paymentprocessor_app_id: "",
        paymentprocessor_secret_id: "",
        paymentprocessor_token: ""
    });

    const { checkLocations, locations, companies } = useContext(LocationContext);
    const [locationOptions, setLocationOptions] = useState([])
    const [companyOptions, setCompanyOptions] = useState([])

    useEffect(() => {
        setTitle(t("paymentProcessorComponent.createPaymentProcessorComponent"));
        setDescription(t("description.paymentProcessor_create"));
        if (id !== undefined) {
            setTitle(t('paymentProcessorComponent.updatePaymentProcessorComponent'));
            setDescription(t('description.paymentProcessor_update'));
        }
    }, [t])

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    getLocationsByCompany(data.company_id, true)
                    // handlerApp.setLoader(false);
                    setDisableButton(false)
                } else {
                    handlerApp.showError(response.message);
                    setDisableButton(true)
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    useEffect(() => {
        if (id !== undefined) {
            sendRequest();
        }
    }, [])

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value, true);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    const handleSubmitLocal = (e) => {
        handlerApp.setLoader(true);
        api.post(endpoint, e)
            .then(response => {
                if (response.success) {
                    checkLocations();
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleUpdate = (e) => {
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, formData)
            .then(response => {
                if (response.success) {
                    checkLocations();
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleKeyDow = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const transformLocations = (loc = null) => {
        let loca = loc ? loc : locations
        let data = loca.map((location) => {
            return {
                code: location.location_id,
                name: location.location_name,
            };
        });
        setLocationOptions(data)
    }

    const transformCompany = () => {
        let data = companies.map((company) => {
            return {
                code: company.company_id,
                name: company.company_name,
            };
        });
        setCompanyOptions(data)
    }

    const getLocationsByCompany = (id, fromSelect = false) => {
        setLocationOptions([])
        if (id) {
            handlerApp.setLoader(true)
            api.get("get_locations_company/" + id)
                .then(response => {
                    if (response.success) {
                        transformLocations(response.data);
                        localStorage.setItem("locations", JSON.stringify(response.data));
                    } else {
                        handlerApp.handlerResponse(response);
                    }
                    if (fromSelect) {
                        handlerApp.setLoader(false)
                    }
                })
                .catch(error => {
                    handlerApp.showError();
                })
        }
    }

    useEffect(() => {
        transformCompany();
        transformLocations();
    }, [locations, companies])

    return (
        <div className="app container">
            <div className="title-section-forms">
                {title}
                <p>{description}</p>
            </div>

            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitLocal : handleUpdate)} noValidate onKeyDown={handleKeyDow}>
                    <div className="row">
                        {profile <= 2 && (
                            <div className="col-md-6 mb-2">
                                <BoldSelectValidated
                                    title={t('companyComponent.company') + " *"}
                                    value={formData.company_id}
                                    name="company_id"
                                    options={companyOptions}
                                    onChange={handleForm}
                                    control={control}
                                    isRequired={true}
                                    register={register}
                                    errors={errors}
                                    dependency={getLocationsByCompany}
                                    disable={id}
                                />
                            </div>
                        )}
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('locationComponent.location')+" *"}
                                value={formData.location_id}
                                name="location_id"
                                options={locationOptions}
                                onChange={handleForm}
                                register={register}
                                errors={errors}
                                required={true}

                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('paymentProcessorComponent.processor')} variant="standard" size="small" required
                                {...register("paymentprocessor_processor", { required: true, maxLength: 30 })}
                                value={formData.paymentprocessor_processor}
                                onChange={handleForm}
                                error={errors.paymentprocessor_processor != null}
                                helperText={
                                    errors.paymentprocessor_processor?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.paymentprocessor_processor?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 30  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('paymentProcessorComponent.merchantId')} variant="standard" size="small" required
                                {...register("paymentprocessor_merchant_id", { required: true, maxLength: 30 })}
                                value={formData.paymentprocessor_merchant_id}
                                onChange={handleForm}
                                error={errors.paymentprocessor_merchant_id != null}
                                helperText={
                                    errors.paymentprocessor_merchant_id?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.paymentprocessor_merchant_id?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 30  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('paymentProcessorComponent.appId')} variant="standard" size="small" required
                                {...register("paymentprocessor_app_id", { required: true, maxLength: 30 })}
                                value={formData.paymentprocessor_app_id}
                                onChange={handleForm}
                                error={errors.paymentprocessor_app_id != null}
                                helperText={
                                    errors.paymentprocessor_app_id?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.paymentprocessor_app_id?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 30 ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('paymentProcessorComponent.secretId')} variant="standard" size="small" required
                                {...register("paymentprocessor_secret_id", { required: true, maxLength: 100 })}
                                value={formData.paymentprocessor_secret_id}
                                onChange={handleForm}
                                error={errors.paymentprocessor_secret_id != null}
                                helperText={
                                    errors.paymentprocessor_secret_id?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.paymentprocessor_secret_id?.type === 'maxLength' ? `${t('validationErrorMessages.long')}100 ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('paymentProcessorComponent.token')} variant="standard" size="small" required
                                {...register("paymentprocessor_token", { required: true, maxLength: 250 })}
                                value={formData.paymentprocessor_token}
                                onChange={handleForm}
                                error={errors.paymentprocessor_token != null}
                                helperText={
                                    errors.paymentprocessor_token?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.paymentprocessor_token?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 250  ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && !disableButton && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default PaymentProcessorsForm;